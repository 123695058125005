<template>
    <div class="project-gallery-area section-space--inner--120">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <!-- section title -->
                    <div class="section-title-area text-center section-space--bottom--50">
                        <h2 class="section-title">Latest Projects</h2>
                        <p class="section-subtitle">Lorem ipsum dolor sit amet, consectetur adipisicing</p>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="project-gallery-wrapper">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="single-gallery-project">
                                    <div class="single-gallery-project__image">
                                        <img src="assets/img/projects/project-13.jpg" class="img-fluid" alt="">
                                    </div>
                                    <div class="single-gallery-project__content">
                                        <h4 class="title">WORK MANAGEMENT</h4>
                                        <router-link to="/project-details">Read More</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-mobile-6">
                                <div class="single-gallery-project">
                                    <div class="single-gallery-project__image">
                                        <img src="assets/img/projects/project-14.jpg" class="img-fluid" alt="">
                                    </div>
                                    <div class="single-gallery-project__content">
                                        <h4 class="title">WORK MANAGEMENT</h4>
                                        <router-link to="/project-details">Read More</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-mobile-6">
                                <div class="single-gallery-project">
                                    <div class="single-gallery-project__image">
                                        <img src="assets/img/projects/project-15.jpg" class="img-fluid" alt="">
                                    </div>
                                    <div class="single-gallery-project__content">
                                        <h4 class="title">WORK MANAGEMENT</h4>
                                        <router-link to="/project-details">Read More</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-mobile-6">
                                <div class="single-gallery-project">
                                    <div class="single-gallery-project__image">
                                        <img src="assets/img/projects/project-16.jpg" class="img-fluid" alt="">
                                    </div>
                                    <div class="single-gallery-project__content">
                                        <h4 class="title">WORK MANAGEMENT</h4>
                                        <router-link to="/project-details">Read More</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-mobile-6">
                                <div class="single-gallery-project">
                                    <div class="single-gallery-project__image">
                                        <img src="assets/img/projects/project-17.jpg" class="img-fluid" alt="">
                                    </div>
                                    <div class="single-gallery-project__content">
                                        <h4 class="title">WORK MANAGEMENT</h4>
                                        <router-link to="/project-details">Read More</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="single-gallery-project">
                                    <div class="single-gallery-project__image">
                                        <img src="assets/img/projects/project-18.jpg" class="img-fluid" alt="">
                                    </div>
                                    <div class="single-gallery-project__content">
                                        <h4 class="title">WORK MANAGEMENT</h4>
                                        <router-link to="/project-details">Read More</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

    };
</script>

<style lang="scss">

</style>